import React, {useEffect} from 'react'
import "./Calendar.css";

import WebFont from 'webfontloader';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCalendarAlt} from '@fortawesome/free-regular-svg-icons'
import '@fortawesome/fontawesome-free/css/all.css';
import NavBar from "../navbar/Navbar";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import bootstrapPlugin from '@fullcalendar/bootstrap';
import Timeline from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'
import 'moment/locale/es-mx';

export default function Calendar(){

    moment.locale('es-mx');
    
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = "GrinBalb - Calendario";

        document.getElementsByClassName('fc-today-button')[0].innerHTML = 'Hoy'
    }, []);

    const groups = [{ id: 1, title: '' }, { id: 2, title: '' }, { id: 3, title: '' }, { id: 4, title: '' }, { id: 5, title: '' }]
 
    const items = [
      {
        id: 1,
        group: 1,
        title: 'GYV123',
        start_time: moment(),
        end_time: moment().add(45, 'day')
      },
      {
        id: 2,
        group: 2,
        title: 'SEC123',
        start_time: moment().add(-20, 'day'),
        end_time: moment().add(25, 'day')
      },
      {
        id: 3,
        group: 3,
        title: 'SEC123',
        start_time: moment().add(-10, 'day'),
        end_time: moment().add(40, 'day')
      },
      {
        id: 4,
        group: 4,
        title: 'SEC123',
        start_time: moment().add(1, 'day'),
        end_time: moment().add(50, 'day')
      },
      {
        id: 5,
        group: 5,
        title: 'SEC123',
        start_time: moment().add(20, 'day'),
        end_time: moment().add(60, 'day')
      }
    ]






    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontSize: 45, fontWeight: 300}}>
                                    <span style={{color: '#A0A0A0'}}>Calendario</span>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={faCalendarAlt} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards p-4'>
                        <FullCalendar
                            plugins={[ dayGridPlugin, bootstrapPlugin ]}
                            locale='esLocale'
                            initialView="dayGridMonth"
                            aspectRatio='2'
                            dayHeaderFormat={{weekday: 'long'}}
                            themeSystem='bootstrap'
                            events={[
                                { title: 'event 1', date: '2022-02-01' },
                                { title: 'event 2', date: '2022-02-02' }
                            ]}
                            />
                    </Card>
                </Row>

                <Row className='mt-5 mb-5'>
                    <Card className='cards p-4'>
                    <Timeline
                        groups={groups}
                        items={items}
                        sidebarWidth={0}
                        defaultTimeStart={moment().startOf("day").add(-20, "day").toDate()}
                        defaultTimeEnd={moment().startOf("day").add(90, "day").toDate()}
                        />
                    </Card>
                </Row>
            </Container>
            
        </div>
    )
}