import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Button, Form, Col} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faShip, faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import NavBar from "../../navbar/Navbar";

import "./NewCover.css";

export default function NewCover(){
    
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = "GrinBalb - Nueva operación";
        init();
    }, []);

    const init = function(){
        window.scrollTo(0, 0);
    }

    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontWeight: 300}}>
                                    <p style={{fontSize: 20}} className='text-muted mb-0'>Nueva operación</p>
                                    <p style={{color: '#A0A0A0', fontSize: 45}}>Carátula</p>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={faShip} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>
                                    <Link to={'/operaciones'} className='linkBlue'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: 8}} />Volver a Operaciones
                                    </Link>
                                    <span className='ms-3' style={{color: '#A0A0A0'}}>|</span><span className='ms-3'>Datos del negocio</span>
                                </td>
                            </tr>
                        </table>
                    

                        <Row className='ms-4 me-4 mt-5'>
                            <Col xs={2} className='cover content rows title'>
                                
                            </Col>
                            <Col style={{textAlign: 'center'}}> 
                                Valores
                            </Col>
                            <Col style={{textAlign: 'center'}}>
                                Unidades
                            </Col>
                            <Col style={{textAlign: 'center'}}>
                                Totales (USD)
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Shipper
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Cliente
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Despachante
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Forwarder (x gs agencia)
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Forwarder (x dif flete)
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                        </Row>
                        
                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Diferencia TC anticipo
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Diferencia TC saldo
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Diferencia impositiva
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Varios
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Total
                            </Col>
                            <Col> 
                            
                            </Col>
                            <Col> 
                                
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                        </Row>

                        <Row className='m-4'>
                            <Col style={{textAlign: 'right'}}>
                                <Link to={'/operaciones/nueva/paso4'}>
                                    <Button variant='primary'>Siguiente</Button>
                                </Link>
                            </Col>
                        </Row>
                    </Card>
                </Row>
            </Container>
        </div>
    )
}