import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import { Routes, Route, Link } from "react-router-dom";
import {Navbar, Container, Nav, Dropdown, Badge, Button} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBell, faDotCircle, faSignOutAlt, faUser} from '@fortawesome/free-solid-svg-icons'
import {faCircle} from '@fortawesome/free-regular-svg-icons'

import "./Navbar.css";

export default function NavBar(){

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto']
            }
        });
    }, []);

    const menus = [{text: "Inicio", link: "/"},
                    {text: "Calendario", link: "/calendario"},
                    {text: "Clientes", link: "/clientes"},
                    {text: "Proveedores", link: "/proveedores"},
                    {text: "Operaciones", link: "/operaciones"},
                    {text: "Reportes", link: "/reportes"}];

    const checkPage = function(key){
        const actualUri = window.location.pathname.split('/')[1];

        if(actualUri === menus[key].link.replace('/', '')){
            return(
                <Link to={menus[key].link} className="menuActive pe-3 ps-3 text-light">{menus[key].text}</Link>
            )
        }else{
            return(
                <Link to={menus[key].link} className="menuNotActive pe-3 ps-3 text-light">{menus[key].text}</Link>
            )
        }
    };
    
    return(
        <div style={{marginBottom: 125, fontFamily: 'Roboto'}}>
            <Navbar fixed="top" className="pb-2" style={{background: "white"}}>
                <Container>
                    <Navbar.Brand>
                        <img
                            src="https://sistema.grinbalb.com/imgs/logo-header.png"
                            height="40"
                            className="d-inline-block align-top"
                            alt="React Bootstrap logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text className='me-3'>
                            
                            <Dropdown>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic" style={{background: "#39aede", borderColor: "#39aede", zIndex: '9'}}>
                                    <img style={{width: 40, borderRadius: "50%"}} src="https://secdevs.com.ar/cbimage.png" alt=""></img>
                                    <span className="ms-3 me-3">Santiago Cerfoglio</span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1"><FontAwesomeIcon icon={faUser} style={{marginRight: 8}} />Mi perfil</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2"><FontAwesomeIcon icon={faSignOutAlt} style={{marginRight: 8}} />Salir</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Navbar.Text>

                        <Navbar.Text>
                            
                            <Dropdown align="end">
                                <Dropdown.Toggle variant="light" id="dropdown-basic" style={{zIndex: '9', height: 52}}>
                                    <FontAwesomeIcon icon={faBell} className='ms-2' style={{marginRight: 8}} /><Badge bg="danger" className='me-2'>2</Badge>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item><p className='m-0'><FontAwesomeIcon icon={faCircle} style={{marginRight: 8}} />Samanta Gauto cargó un pago en GYV123</p><p className='m-0' style={{paddingLeft: 24, fontSize: 10}}>Hace 10 minutos</p></Dropdown.Item>
                                    <Dropdown.Item><p className='m-0'><FontAwesomeIcon icon={faCircle} style={{marginRight: 8}} />Diego Balboni creó la operación SEC123</p><p className='m-0' style={{paddingLeft: 24, fontSize: 10}}>Hace 2 horas</p></Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Navbar fixed="top" style={{background: "#39aede", marginTop: 85, zIndex: '10', height: 41}}>
                <Container>
                    <Nav className="flex-grow-1 justify-content-center pb-0">
                        {menus.map((menu, i) => (
                            <div key={i}>{checkPage(i)}</div>
                        ))}
                    </Nav>
                </Container>
            </Navbar>
        </div>
    )
}