import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Col, Container, Row, Card, Button, Table, Dropdown, DropdownButton, Badge} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faDownload, faFileAlt, faFilePdf, faPaperPlane} from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";

import "./Reports.css";

export default function Customers(){

    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);
    
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = "GrinBalb - Reportes";
        setupTable();
    }, []);

    const setupTable = function(){
        const cols = ['Cliente', 'Estado', ''];
        setTableColumns(cols);

        //Todo: Inicio harcodeo

        const rows = [{
            customer: "secdevs",
            status: <Badge bg="success" className='opTable status'>Enviado</Badge>,
            action: <div><Button className='me-2 buttonBlue' variant="primary">Ver</Button></div>
        },
        {
            customer: "Guata y Vellón",
            status: <Badge bg="danger" className='opTable status'>No enviado</Badge>,
            action: <div><Button className='me-2 buttonBlue' variant="primary">Ver</Button><Button className='' variant="success">Enviar</Button></div>
        },
        {
            customer: "Carrefour",
            status: <Badge bg="danger" className='opTable status'>No enviado</Badge>,
            action: <div><Button className='me-2 buttonBlue' variant="primary">Ver</Button><Button className='' variant="success">Enviar</Button></div>
        }];
        setTableRows(rows);
    };

    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontSize: 45, fontWeight: 300}}>
                                    <span style={{color: '#A0A0A0'}}>Reportes</span>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={faFileAlt} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Col>
                        <Card className='cards mb-5' style={{height: 400, textAlign: 'center'}}>
                            <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
                                <p className='mb-0' style={{fontSize: 30}}>Reporte semanal</p>
                                <p className='text-muted mt-0'>Generado el viernes 7 de enero</p>
                                <p className='mt-5'><FontAwesomeIcon icon={faFilePdf} style={{fontSize: 80, color: "A0A0A0"}} /></p>
                                <p className='mt-4'><Button variant="success"><FontAwesomeIcon icon={faDownload} style={{marginRight: 8}} />Descargar</Button></p>
                            </div>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='cards mb-5' style={{height: 400}}>
                            <table className='header content'>
                                <tr>
                                    <td className='header rows left'>
                                        <DropdownButton id="dropdown-basic-button" variant='light' title="7 de enero">
                                            <Dropdown.Item href="#/action-1">30 de diciembre</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">23 de diciembre</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">17 de diciembre</Dropdown.Item>
                                        </DropdownButton>
                                    </td>
                                    <td className='header rows right'>
                                        <Button variant="success"><FontAwesomeIcon icon={faPaperPlane} style={{marginRight: 8}} />Enviar todos</Button>
                                    </td>
                                </tr>
                            </table>
                            
                            <Table striped hover className='mt-3'>
                                <thead>
                                    <tr>
                                        {tableColumns.map((c, i) => (
                                            <th key={i} className='opTable head'>{c}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableRows.map((r, i) => (
                                        <tr key={i} >
                                            <td className='opTable rows align-middle'>{r.customer}</td>
                                            <td className='opTable rows align-middle'>{r.status}</td>
                                            <td className='opTable rows align-middle'>{r.action}</td>
                                        </tr>
                                    ))}
                                    
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                    
                </Row>
            </Container>
            
        </div>
    )
}