import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Button, Form, Col} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faShip, faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import NavBar from "../../navbar/Navbar";

import "./NewCover.css";

export default function NewCover(){

    const [origin, setOrigin] = React.useState('');
    const [countries, setCountries] = React.useState([]);
    
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = "GrinBalb - Nueva operación";
        init();
    }, []);

    const init = function(){
        window.scrollTo(0, 0);
        setCountries(['Corea del Sur', 'China']);
    }

    const handleOrigin = (event) => {
        setOrigin(event.target.value);
    };

    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontWeight: 300}}>
                                    <p style={{fontSize: 20}} className='text-muted mb-0'>Nueva operación</p>
                                    <p style={{color: '#A0A0A0', fontSize: 45}}>Carátula</p>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={faShip} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>
                                    <Link to={'/operaciones'} className='linkBlue'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: 8}} />Volver a Operaciones
                                    </Link>
                                    <span className='ms-3' style={{color: '#A0A0A0'}}>|</span><span className='ms-3'>Datos de carga</span>
                                </td>
                            </tr>
                        </table>
                    

                        <Row className='ms-4 me-4 mt-5'>
                            <Col xs={2} className='cover content rows title'>
                                
                            </Col>
                            <Col style={{textAlign: 'center'}}> 
                                Valores
                            </Col>
                            <Col style={{textAlign: 'center'}}>
                                Unidades
                            </Col>
                            <Col style={{textAlign: 'center'}}>
                                Totales (USD)
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Mercadería
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Posición arancelaria
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                            
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Origen
                            </Col>
                            <Col> 
                                <Form.Select className='' onChange = {(event) => handleOrigin(event)} id="elementos_simbolicos_marcha">
                                    <option value="0">Seleccionar...</option>
                                    {countries.map((c, i) => (
                                        <option key={i} id={i} value={c}>{c}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col> 
                                
                            </Col>
                            <Col> 
                            
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                ETD
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                
                            </Col>
                            <Col> 
                            
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                ETA
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                
                            </Col>
                            <Col> 
                            
                            </Col>
                        </Row>
                        
                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Licencia
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                
                            </Col>
                            <Col> 
                            
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Cantidad
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                            
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                FOB real
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                CIF real
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Intercom
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                
                            </Col>
                            <Col> 
                                
                            </Col>
                        </Row>
                        
                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                CNTR
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                FOB total
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>
                            <Col> 
                                
                            </Col>
                            <Col> 
                                
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3 mb-5'>
                            <Col xs={2} className='cover content rows title'>
                                Condición de pago
                            </Col>
                            <Col> 
                                <Form.Control className='' type="text" id="" placeholder="" />
                            </Col>

                        </Row>

                        <Row className='m-4'>
                            <Col style={{textAlign: 'right'}}>
                                <Link to={'/operaciones/nueva/paso3'}>
                                    <Button variant='primary'>Siguiente</Button>
                                </Link>
                            </Col>
                        </Row>
                    </Card>
                </Row>
            </Container>
        </div>
    )
}