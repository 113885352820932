import React, {useEffect} from 'react'
import $ from 'jquery';
import WebFont from 'webfontloader';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Button, Form, Col} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faShip, faArrowLeft, faSearch} from '@fortawesome/free-solid-svg-icons'
import NavBar from "../../navbar/Navbar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import "./NewCover.css";

export default function NewCover(){

    const [operationType, setOperationType] = React.useState('');
    const [operationTypes, setOperationTypes] = React.useState([]);
    const [date, setDate] = React.useState(new Date());
    
    
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = "GrinBalb - Nueva operación";
        init();
    }, []);

    const init = function(){
        window.scrollTo(0, 0);
        registerLocale('es', es);

        setOperationTypes(['Broker', 'Trader']);
    }

    const handleOperationType = (event) => {
        setOperationType(event.target.value);
    };

    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontWeight: 300}}>
                                    <p style={{fontSize: 20}} className='text-muted mb-0'>Nueva operación</p>
                                    <p style={{color: '#A0A0A0', fontSize: 45}}>Carátula</p>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={faShip} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>
                                    <Link to={'/operaciones'} className='linkBlue'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: 8}} />Volver a Operaciones
                                    </Link>
                                    <span className='ms-3' style={{color: '#A0A0A0'}}>|</span><span className='ms-3'>Datos generales</span>
                                </td>
                            </tr>
                        </table>


                        <Row className='ms-4 me-4 mt-5'>
                            <Col xs={2} className='cover content rows title'>
                                Fecha de creación
                            </Col>
                            <Col> 
                                <DatePicker className='form-control cover content rows element' locale="es" dateFormat="dd/MM/yyyy" selected={date} onChange={(d) => setDate(d)} />
                            </Col>
                            <Col>
                                
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Tipo de operación
                            </Col>
                            <Col> 
                                <Form.Select className='cover content rows element' onChange = {(event) => handleOperationType(event)} id="elementos_simbolicos_marcha">
                                    <option value="0">Seleccionar...</option>
                                    {operationTypes.map((ot, i) => (
                                        <option key={i} id={i} value={ot}>{ot}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col>
                                
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-5'>
                            <Col xs={2} className='cover content rows title'>
                                
                            </Col>
                            <Col style={{textAlign: 'center'}}> 
                                Empresa
                            </Col>
                            <Col style={{textAlign: 'center'}}>
                                Contacto
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Importador
                            </Col>
                            <Col> 
                                <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" />
                                <Button variant='primary' className='ms-2 cover content rows searchbtn'><FontAwesomeIcon icon={faSearch}/></Button>
                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" />
                                <Button variant='primary' className='ms-2 cover content rows searchbtn'><FontAwesomeIcon icon={faSearch}/></Button>
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Exportador
                            </Col>
                            <Col> 
                                <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" />
                                <Button variant='primary' className='ms-2 cover content rows searchbtn'><FontAwesomeIcon icon={faSearch}/></Button>
                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" />
                                <Button variant='primary' className='ms-2 cover content rows searchbtn'><FontAwesomeIcon icon={faSearch}/></Button>
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Cliente
                            </Col>
                            <Col> 
                                <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" />
                                <Button variant='primary' className='ms-2 cover content rows searchbtn'><FontAwesomeIcon icon={faSearch}/></Button>
                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" />
                                <Button variant='primary' className='ms-2 cover content rows searchbtn'><FontAwesomeIcon icon={faSearch}/></Button>
                            </Col>
                        </Row>
                        
                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Despachante
                            </Col>
                            <Col> 
                                <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" />
                                <Button variant='primary' className='ms-2 cover content rows searchbtn'><FontAwesomeIcon icon={faSearch}/></Button>
                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" />
                                <Button variant='primary' className='ms-2 cover content rows searchbtn'><FontAwesomeIcon icon={faSearch}/></Button>
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3 mb-5'>
                            <Col xs={2} className='cover content rows title'>
                                Forwarder
                            </Col>
                            <Col> 
                                <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" />
                                <Button variant='primary' className='ms-2 cover content rows searchbtn'><FontAwesomeIcon icon={faSearch}/></Button>
                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" />
                                <Button variant='primary' className='ms-2 cover content rows searchbtn'><FontAwesomeIcon icon={faSearch}/></Button>
                            </Col>
                        </Row>

                        <Row className='m-4'>
                            <Col style={{textAlign: 'right'}}>
                                <Link to={'/operaciones/nueva/paso2'}>
                                    <Button variant='primary'>Siguiente</Button>
                                </Link>
                            </Col>
                        </Row>



                    
                    </Card>
                </Row>
            </Container>
        </div>
    )
}