import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Table, Badge, Button, Form, DropdownButton, Dropdown} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faShip, faPlusCircle} from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";

import "./Operations.css";

export default function Operations(){

    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);
    
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = "GrinBalb - Operaciones";
        setupTable();
    }, []);

    const setupTable = function(){
        const cols = ['Código', 'Cliente', 'Última modificación', 'Estado', ''];
        setTableColumns(cols);

        //Todo: Inicio harcodeo

        const rows = [{
            id: 'GYV140',
            client: 'Guata y Vellon',
            lastMod: 'hace 15 minutos',
            status: <Badge bg="warning" className='opTable status'>Abierta</Badge>,
            action: <Link to={'/operaciones/detalles'}><Button className='buttonBlue' variant="primary">Ver detalles</Button></Link>
        },
        {
            id: 'GYV140',
            client: 'Guata y Vellon',
            lastMod: 'hace 15 minutos',
            status: <Badge bg="warning" className='opTable status'>Abierta</Badge>,
            action: <Button className='buttonBlue' variant="primary">Ver detalles</Button>
        },
        {
            id: 'GYV140',
            client: 'Guata y Vellon',
            lastMod: 'hace 15 minutos',
            status: <Badge bg="warning" className='opTable status'>Abierta</Badge>,
            action: <Button className='buttonBlue' variant="primary">Ver detalles</Button>
        },
        {
            id: 'GYV140',
            client: 'Guata y Vellon',
            lastMod: 'hace 15 minutos',
            status: <Badge bg="warning" className='opTable status'>Abierta</Badge>,
            action: <Button className='buttonBlue' variant="primary">Ver detalles</Button>
        }];
        setTableRows(rows);
    };

    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontSize: 45, fontWeight: 300}}>
                                    <span style={{color: '#A0A0A0'}}>Operaciones</span>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={faShip} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>
                                    <Form>
                                        <Form.Check inline label="Abiertas" name="group1" type='checkbox' id={1}/>
                                        <Form.Check inline label="Cerradas" name="group1" type='checkbox' id={2}/>
                                    </Form>
                                </td>
                                <td>
                                    <DropdownButton id="dropdown-basic-button" variant='light' title="Ordenar por última modificación">
                                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                                <td className='header rows right'>
                                    <Link to={'/operaciones/nueva/paso1'}>
                                        <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{marginRight: 8}} />Nueva operación</Button>
                                    </Link>
                                </td>
                            </tr>
                        </table>
                        




                        <Table striped hover className='mt-3'>
                            <thead>
                                <tr>
                                    {tableColumns.map((c, i) => (
                                        <th key={i} className='opTable head'>{c}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {tableRows.map((r, i) => (
                                    <tr key={i} >
                                        <td className='opTable rows align-middle'>{r.id}</td>
                                        <td className='opTable rows align-middle'>{r.client}</td>
                                        <td className='opTable rows align-middle'>{r.lastMod}</td>
                                        <td className='opTable rows align-middle'>{r.status}</td>
                                        <td className='opTable rows align-middle'>{r.action}</td>
                                    </tr>
                                ))}
                                
                            </tbody>
                        </Table>
                    </Card>
                </Row>
            </Container>
        </div>
    )
}