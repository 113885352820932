import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Col, Card, Badge, Button, Table} from "react-bootstrap";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import bootstrapPlugin from '@fullcalendar/bootstrap';
import listPlugin from '@fullcalendar/list';

import NavBar from "../navbar/Navbar";
import "./Home.css";


export default function Home(){
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);
    
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto']
            }
        });

        document.title = "GrinBalb - Inicio";

        document.getElementsByClassName('fc-today-button')[0].innerHTML = 'Hoy';
        setupOpTable();
    }, []);

    const setupOpTable = function(){
        const cols = ['Código', 'Cliente', 'Estado', ''];
        setTableColumns(cols);

        //Todo: Inicio harcodeo

        const rows = [{
            id: 'GYV140',
            client: 'Guata y Vellon',
            lastMod: 'hace 15 minutos',
            status: <Badge bg="warning" className='opTable status'>Abierta</Badge>,
            action: <Link to={'/operaciones/detalles'}><Button className='buttonBlue' variant="primary">Ver detalles</Button></Link>
        },
        {
            id: 'GYV140',
            client: 'Guata y Vellon',
            lastMod: 'hace 15 minutos',
            status: <Badge bg="warning" className='opTable status'>Abierta</Badge>,
            action: <Button className='buttonBlue' variant="primary">Ver detalles</Button>
        },
        {
            id: 'GYV140',
            client: 'Guata y Vellon',
            lastMod: 'hace 15 minutos',
            status: <Badge bg="warning" className='opTable status'>Abierta</Badge>,
            action: <Button className='buttonBlue' variant="primary">Ver detalles</Button>
        },
        {
            id: 'GYV140',
            client: 'Guata y Vellon',
            lastMod: 'hace 15 minutos',
            status: <Badge bg="warning" className='opTable status'>Abierta</Badge>,
            action: <Button className='buttonBlue' variant="primary">Ver detalles</Button>
        }];
        setTableRows(rows);
    };

    return(
        <div className='p-0 mt-5' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <Container className='mt-5'>
                <Row>
                    <Col>
                        <Card className='statusCard green'>
                            <Card.Body>
                                <Card.Title>Operaciones</Card.Title>
                                <Card.Text>
                                    <p className='m-0' style={{fontSize: 28, fontStyle: 'bold'}}>4</p>
                                    <p className='m-0'>operaciones abiertas</p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='statusCard green'>
                            <Card.Body>
                                <Card.Title>Eventos</Card.Title>
                                <Card.Text>
                                    <p className='m-0' style={{fontSize: 28, fontStyle: 'bold'}}>3</p>
                                    <p className='m-0'>eventos hoy</p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='statusCard red'>
                            <Card.Body>
                                <Card.Title>Indicador</Card.Title>
                                <Card.Text>
                                    <p className='m-0' style={{fontSize: 28, fontStyle: 'bold'}}>4</p>
                                    <p className='m-0'>operaciones</p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='statusCard yellow'>
                            <Card.Body>
                                <Card.Title>Reportes</Card.Title>
                                <Card.Text>
                                    <p className='m-0' style={{fontSize: 28, fontStyle: 'bold'}}>4</p>
                                    <p className='m-0'>pendientes de envío</p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className='mb-5'>
                    <Col>
                        <Card className='cards mt-5' style={{height: 430}}>
                            <Card.Body>
                                <Card.Title>Calendario</Card.Title>
                                <Card.Text>
                                    <FullCalendar
                                        style={{height: 350}}
                                        plugins={[ listPlugin, bootstrapPlugin ]}
                                        locale='esLocale'
                                        initialView='listWeek'
                                        aspectRatio='2'
                                        dayHeaderFormat={{weekday: 'long'}}
                                        themeSystem='bootstrap'
                                        events={[
                                            { title: 'event 1', date: '2022-02-22' },
                                            { title: 'event 1', date: '2022-02-22' },
                                            { title: 'event 1', date: '2022-02-22' },
                                            { title: 'event 2', date: '2022-02-23' },
                                            { title: 'event 1', date: '2022-02-23' },
                                            { title: 'event 1', date: '2022-02-24' }
                                        ]}
                                    />
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='cards mt-5' style={{height: 430}}>
                            <Card.Body>
                                <Card.Title>Operaciones</Card.Title>
                                <Card.Text>
                                <Table striped hover className='mt-3'>
                            <thead>
                                <tr>
                                    {tableColumns.map((c, i) => (
                                        <th key={i} className='opTable head'>{c}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {tableRows.map((r, i) => (
                                    <tr key={i} >
                                        <td className='opTable rows align-middle'>{r.id}</td>
                                        <td className='opTable rows align-middle'>{r.client}</td>
                                        <td className='opTable rows align-middle'>{r.status}</td>
                                        <td className='opTable rows align-middle'>{r.action}</td>
                                    </tr>
                                ))}
                                
                            </tbody>
                        </Table>
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        
                    </Col>
                </Row>
                
            </Container>


            
            

        </div>
    )
}