import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Button, Col} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faShip, faArrowLeft, faFilePdf, faFileExcel} from '@fortawesome/free-solid-svg-icons'
import NavBar from "../../navbar/Navbar";
import Dropzone from 'react-dropzone'
import "./NewCover.css";

export default function NewCover(){
  
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = "GrinBalb - Nueva operación";
        init();
    }, []);

    const init = function(){
        window.scrollTo(0, 0);
    }
    
    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontWeight: 300}}>
                                    <p style={{fontSize: 20}} className='text-muted mb-0'>Nueva operación</p>
                                    <p style={{color: '#A0A0A0', fontSize: 45}}>Carátula</p>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={faShip} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>
                                    <Link to={'/operaciones'} className='linkBlue'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: 8}} />Volver a Operaciones
                                    </Link>
                                    <span className='ms-3' style={{color: '#A0A0A0'}}>|</span><span className='ms-3'>Documentación</span>
                                </td>
                            </tr>
                        </table>
                    

                        <Container>
                            <Row className='mt-5 mb-4'>
                                <Col style={{textAlign: 'center', marginTop: 'auto', marginBottom: 'auto'}}>
                                    <div>
                                        <p className='mb-0' style={{fontSize: 30}}>Factura proforma</p>
                                        <p className='mt-5'><FontAwesomeIcon icon={faFilePdf} style={{fontSize: 80, color: "A0A0A0"}} /></p>
                                        <p className='text-muted mt-0'>Archivo PDF</p>
                                        <p className='mt-4'>
                                            <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section className='dropzone pt-5 pb-5'>
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        <p>Arrastrá el archivo o hacé clic para buscarlo</p>
                                                    </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </p>
                                    </div>
                                </Col>
                                <Col style={{textAlign: 'center', marginTop: 'auto', marginBottom: 'auto'}}>
                                    <div>
                                        <p className='mb-0' style={{fontSize: 30}}>Cotización</p>
                                        <p className='mt-5'><FontAwesomeIcon icon={faFileExcel} style={{fontSize: 80, color: "A0A0A0"}} /></p>
                                        <p className='text-muted mt-0'>Archivo Excel</p>
                                        <p className='mt-4'>
                                            <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section className='dropzone pt-5 pb-5'>
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        <p>Arrastrá el archivo o hacé clic para buscarlo</p>
                                                    </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </p>
                                    </div>
                                </Col>
                                <Col style={{textAlign: 'center', marginTop: 'auto', marginBottom: 'auto'}}>
                                    <div>
                                        <p className='mb-0' style={{fontSize: 30}}>Cotización</p>
                                        <p className='mt-5'><FontAwesomeIcon icon={faFileExcel} style={{fontSize: 80, color: "A0A0A0"}} /></p>
                                        <p className='text-muted mt-0'>Archivo Excel</p>
                                        <p className='mt-4'>
                                            <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section className='dropzone pt-5 pb-5'>
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        <p>Arrastrá el archivo o hacé clic para buscarlo</p>
                                                    </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                        <Row className='m-4'>
                            <Col style={{textAlign: 'right'}}>
                                <Link to={'/operaciones/nueva/ok'}>
                                    <Button variant='primary'>Siguiente</Button>
                                </Link>
                            </Col>
                        </Row>
                    </Card>
                </Row>
            </Container>
        </div>
    )
}