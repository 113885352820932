import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Button} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faShip, faArrowLeft, faCheckCircle} from '@fortawesome/free-solid-svg-icons'
import NavBar from "../../navbar/Navbar";
import "./NewCover.css";

export default function NewCover(){
  
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = "GrinBalb - Nueva operación";
        init();
    }, []);

    const init = function(){
        window.scrollTo(0, 0);
    }

    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#28A745'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontWeight: 300}}>
                                    <p style={{color: 'white', fontSize: 45}}>Nueva operación</p>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={faShip} style={{fontSize: 140, color: "white"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>
                                    <Link to={'/operaciones'} className='linkBlue'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: 8}} />Volver a Operaciones
                                    </Link>
                                </td>
                            </tr>
                        </table>
                    
                        <Container>
                            <Row className='mt-5 mb-5' style={{textAlign: 'center'}}>
                                <p style={{fontSize: 70, color: '#28A745'}}><FontAwesomeIcon icon={faCheckCircle} style={{marginRight: 8}} /></p>
                                <p className='m-0' style={{fontSize: 20}}>¡Listo!</p>
                                <p className='m-0' style={{fontSize: 20}}>Operación creada correctamente</p>
                                <p className='mt-4' style={{fontSize: 40}}>GYV145</p>
                                <Link to={'/operaciones/nueva/ok'} className='mt-4 mb-4'>
                                    <Button variant='primary'>Entrar a la operación</Button>
                                </Link>
                            </Row>
                        </Container>

                    </Card>
                </Row>
            </Container>
        </div>
    )
}