import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Button, Table, Dropdown, DropdownButton, Badge} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faIndustry, faPlusCircle} from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";

import "./Providers.css";

export default function Providers(){

    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);
    
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = "GrinBalb - Proveedores";
        setupTable();
    }, []);

    const setupTable = function(){
        const cols = ['Nombre', 'CUIT', 'Razón social', ''];
        setTableColumns(cols);

        //Todo: Inicio harcodeo

        const rows = [
        {
            code: "BBV",
            name: "BBVA",
            cuit: '20-41758060-4',
            razonSocial: 'Banco Bilbao Vizcaya Argentaria S.A.',
            operations: <div><Badge bg="warning" className='me-2 opTable status'>1</Badge><Badge bg="success" className='opTable status'>5</Badge></div>,
            action: <Button className='buttonBlue' variant="primary">Ver detalles</Button>
        },
        {
            code: "BMW",
            name: "BMW",
            cuit: '20-41758060-4',
            razonSocial: 'BMW Ibérica S.A.',
            operations: <div><Badge bg="warning" className='me-2 opTable status'>1</Badge><Badge bg="success" className='opTable status'>5</Badge></div>,
            action: <Button className='buttonBlue' variant="primary">Ver detalles</Button>
        },
        {
            code: "AIE",
            name: "Air Europa",
            cuit: '20-41758060-4',
            razonSocial: 'Air Europa Líneas Aéreas S.A.',
            operations: <div><Badge bg="warning" className='me-2 opTable status'>1</Badge><Badge bg="success" className='opTable status'>5</Badge></div>,
            action: <Button className='buttonBlue' variant="primary">Ver detalles</Button>
        },
        {
            code: "SEC",
            name: "secdevs",
            cuit: '20-41758060-4',
            razonSocial: 'CERFOGLIO SANTIAGO EDUARDO',
            operations: <div><Badge bg="warning" className='me-2 opTable status'>1</Badge><Badge bg="success" className='opTable status'>5</Badge></div>,
            action: <Button className='buttonBlue' variant="primary">Ver detalles</Button>
        },
        {
            code: "MCD",
            name: "McDonald's",
            cuit: '20-41758060-4',
            razonSocial: 'Restaurantes McDonalds S.A',
            operations: <div><Badge bg="warning" className='me-2 opTable status'>1</Badge><Badge bg="success" className='opTable status'>5</Badge></div>,
            action: <Button className='buttonBlue' variant="primary">Ver detalles</Button>
        }];
        setTableRows(rows);
    };

    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontSize: 45, fontWeight: 300}}>
                                    <span style={{color: '#A0A0A0'}}>Proveedores</span>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={faIndustry} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>
                                    <DropdownButton id="dropdown-basic-button" variant='light' title="Ordenar por razón social">
                                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                                <td className='header rows right'>
                                    <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{marginRight: 8}} />Nuevo proveedor</Button>
                                </td>
                            </tr>
                        </table>
                        




                        <Table striped hover className='mt-3'>
                            <thead>
                                <tr>
                                    {tableColumns.map((c, i) => (
                                        <th key={i} className='opTable head'>{c}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {tableRows.map((r, i) => (
                                    <tr key={i} >
                                        <td className='opTable rows align-middle'>{r.name}</td>
                                        <td className='opTable rows align-middle'>{r.cuit}</td>
                                        <td className='opTable rows align-middle'>{r.razonSocial}</td>
                                        <td className='opTable rows align-middle'>{r.action}</td>
                                    </tr>
                                ))}
                                
                            </tbody>
                        </Table>
                    </Card>
                </Row>
            </Container>
            
        </div>
    )
}