import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {Row} from "react-bootstrap";

import Home from "./components/ui/home/Home";
import Calendar from "./components/ui/calendar/Calendar";
import Customers from "./components/ui/customers/Customers";
import Operations from "./components/ui/operations/Operations";
import NewCoverStep1 from "./components/ui/operations/newCover/NewCoverStep1";
import NewCoverStep2 from "./components/ui/operations/newCover/NewCoverStep2";
import NewCoverStep3 from "./components/ui/operations/newCover/NewCoverStep3";
import NewCoverStep4 from "./components/ui/operations/newCover/NewCoverStep4";
import NewCoverCreated from "./components/ui/operations/newCover/NewCoverCreated";
import DetailedOperation from "./components/ui/operations/detailedOperation/DetailedOperation";
import Providers from "./components/ui/providers/Providers";
import Reports from "./components/ui/reports/Reports";

export function App(){
    return (
        <div>
            <Router>
                <Row className="m-0">
                    <Routes>
                        <Route path="/" element={<Home/>} />
                        <Route path="/calendario" element={<Calendar/>} />
                        <Route path="/clientes" element={<Customers/>} />
                        <Route path="/operaciones" element={<Operations/>} />
                        <Route path="/operaciones/nueva/paso1" element={<NewCoverStep1/>} />
                        <Route path="/operaciones/nueva/paso2" element={<NewCoverStep2/>} />
                        <Route path="/operaciones/nueva/paso3" element={<NewCoverStep3/>} />
                        <Route path="/operaciones/nueva/paso4" element={<NewCoverStep4/>} />
                        <Route path="/operaciones/nueva/ok" element={<NewCoverCreated/>} />
                        <Route path="/operaciones/detalles/" element={<DetailedOperation/>} />
                        <Route path="/proveedores" element={<Providers/>} />
                        <Route path="/reportes" element={<Reports/>} />
                    </Routes>
                </Row>
            </Router>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous"/>
        </div>
    )
}