import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Button, Col, Accordion, Badge, Dropdown, Form} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faShip, faArrowLeft, faFilePdf, faFileExcel, faEye, faDownload, faTrash, faEllipsisV, faSearch, faEdit, faPen, faPlusCircle} from '@fortawesome/free-solid-svg-icons'
import NavBar from "../../navbar/Navbar";
import Dropzone from 'react-dropzone'
import {Helmet} from "react-helmet";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import "./DetailedOperation.css";

export default function NewCover(){

    const [operationType, setOperationType] = React.useState('');
    const [operationTypes, setOperationTypes] = React.useState([]);
    const [date, setDate] = React.useState(new Date());
    const [origin, setOrigin] = React.useState('');
    const [countries, setCountries] = React.useState([]);
  
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = "GrinBalb - Operación GYV123";
        init();
    }, []);

    const handleOperationType = (event) => {
        setOperationType(event.target.value);
    };

    const handleOrigin = (event) => {
        setOrigin(event.target.value);
    };

    const init = function(){
        //window.scrollTo(0, 0);
    }

    const cover = function(date){
        return(
            <div className='p-0'>
                <Accordion className='p-0'>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <div>
                                <p className='m-0'>Carátula</p>
                                <p className='mt-3 mb-0' style={{fontSize: 12}}><img className='me-1' style={{width: 20, borderRadius: "50%"}} src="https://secdevs.com.ar/cbimage.png" alt=""></img> <span style={{marginTop: 'auto', marginBottom: 'auto', height: 20, verticalAlign: 'middle'}}>Gabriel Alvarez @ {date}</span></p>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Row className='ms-4 me-4 mt-4'>
                                <Col xs={2} className='cover content rows title'>
                                    Fecha de creación
                                </Col>
                                <Col> 
                                    <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" value="10/01/2022" />
                                </Col>
                                <Col>
                                    
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    Tipo de operación
                                </Col>
                                <Col> 
                                    <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" value="Trading"  />  
                                </Col>
                                <Col>
                                    
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-5'>
                                <Col xs={2} className='cover content rows title'>
                                    
                                </Col>
                                <Col style={{textAlign: 'center'}}> 
                                    Empresa
                                </Col>
                                <Col style={{textAlign: 'center'}}>
                                    Contacto
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    Importador
                                </Col>
                                <Col> 
                                    <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" value="GrinBalb"  />
                                </Col>
                                <Col>
                                    
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    Exportador
                                </Col>
                                <Col> 
                                    <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" value="Toray"  />
                                </Col>
                                <Col>
                                    <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" value="Juan Perez"  />
                                    <Button variant='primary' className='ms-2 cover content rows searchbtn'><FontAwesomeIcon icon={faSearch}/></Button>
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    Cliente
                                </Col>
                                <Col> 
                                    <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" value="Guata y Vellon"  />
                                </Col>
                                <Col>
                                    <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" value="Marcelo Singer" />
                                    <Button variant='primary' className='ms-2 cover content rows searchbtn'><FontAwesomeIcon icon={faSearch}/></Button>
                                </Col>
                            </Row>
                            
                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    Despachante
                                </Col>
                                <Col> 
                                    <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" />
                                    <Button variant='primary' className='ms-2 cover content rows searchbtn'><FontAwesomeIcon icon={faSearch}/></Button>
                                </Col>
                                <Col>
                                    <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" />
                                    <Button variant='primary' className='ms-2 cover content rows searchbtn'><FontAwesomeIcon icon={faSearch}/></Button>
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-3 mb-5'>
                                <Col xs={2} className='cover content rows title'>
                                    Forwarder
                                </Col>
                                <Col> 
                                    <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" value="Toray"  />
                                    <Button variant='primary' className='ms-2 cover content rows searchbtn'><FontAwesomeIcon icon={faSearch}/></Button>
                                </Col>
                                <Col>
                                    <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" value="Juan Perez"  />
                                    <Button variant='primary' className='ms-2 cover content rows searchbtn'><FontAwesomeIcon icon={faSearch}/></Button>
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-5'>
                                <Col xs={2} className='cover content rows title'>
                                    
                                </Col>
                                <Col style={{textAlign: 'center'}}> 
                                    Valores
                                </Col>
                                <Col style={{textAlign: 'center'}}>
                                    Unidades
                                </Col>
                                <Col style={{textAlign: 'center'}}>
                                    Totales (USD)
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    Mercadería
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value="PSF LM 4x51" />
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value="42000"/>
                                </Col>
                                <Col> 

                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    Posición arancelaria
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" />
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" />
                                </Col>
                                <Col> 
                                
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    Origen
                                </Col>
                                <Col> 
                                    <Form.Select className='' onChange = {(event) => handleOrigin(event)} id="elementos_simbolicos_marcha">
                                        <option value="0">Corea del Sur</option>
                                        {countries.map((c, i) => (
                                            <option key={i} id={i} value={c}>{c}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col> 
                                    
                                </Col>
                                <Col> 
                                
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    ETD
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value="10/03/2022"/>
                                </Col>
                                <Col> 
                                    
                                </Col>
                                <Col> 
                                
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    ETA
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value="30/04/2022" />
                                </Col>
                                <Col> 
                                    
                                </Col>
                                <Col> 
                                
                                </Col>
                            </Row>
                            
                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    Licencia
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value="Automática" />
                                </Col>
                                <Col> 
                                    
                                </Col>
                                <Col> 
                                
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    Cantidad
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value="42,0" />
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value="Tns" />
                                </Col>
                                <Col> 
                                
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    FOB real
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value="1,19" />
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value="USD/KG"/>
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value="49.980,00" />
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    CIF real
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value="1,67"/>
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value="USD/KG"/>
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value="70.140,00"/>
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    Intercom
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value="CIF" />
                                </Col>
                                <Col> 
                                    
                                </Col>
                                <Col> 
                                    
                                </Col>
                            </Row>
                            
                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    CNTR
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value="2"/>
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='40"'/>
                                </Col>
                                <Col> 
                                    
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    FOB total
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value="7054520210917A"/>
                                </Col>
                                <Col> 
                                    
                                </Col>
                                <Col> 
                                    
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-3 mb-5'>
                                <Col xs={2} className='cover content rows title'>
                                    Condición de pago
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value="T/T PAYMENT 100% AGAINST B/L COPY"/>
                                </Col>

                            </Row>

                            <Row className='ms-4 me-4 mt-5'>
                                <Col xs={2} className='cover content rows title'>
                                    
                                </Col>
                                <Col style={{textAlign: 'center'}}> 
                                    Valores
                                </Col>
                                <Col style={{textAlign: 'center'}}>
                                    Unidades
                                </Col>
                                <Col style={{textAlign: 'center'}}>
                                    Totales (USD)
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    Shipper
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value=''/>
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='%/PI' />
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='' />
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    Cliente
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='' />
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='USD' />
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='' />
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    Despachante
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='10%' />
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='%/HON DESP' />
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='' />
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    Forwarder (x gs agencia)
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='' />
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='USD/CNTR 40"' />
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='' />
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    Forwarder (x dif flete)
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='' />
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='USD/CNTR 40"' />
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='' />
                                </Col>
                            </Row>
                            
                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    Diferencia TC anticipo
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='' />
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='' />
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder=""  value=''/>
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    Diferencia TC saldo
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='' />
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='' />
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='' />
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    Diferencia impositiva
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='' />
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='' />
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='' />
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    Varios
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='' />
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='DIF DE FOB' />
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" value='' />
                                </Col>
                            </Row>

                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    Total
                                </Col>
                                <Col> 
                                
                                </Col>
                                <Col> 
                                    
                                </Col>
                                <Col> 
                                    <Form.Control className='' type="text" id="" placeholder="" />
                                </Col>
                            </Row>

                            <Row className='m-4'>
                                <Col style={{textAlign: 'right'}}>
                                    <Link to={'/operaciones/nueva/paso4'}>
                                        <Button variant='primary'>Guardar</Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        )
    }

    const payment = function(date){
        return (
            <Accordion className='p-0'>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <div>
                            <p className='m-0'>Pago recibido de Guata y Vellon</p>
                            <p className='m-0 pt-1' style={{fontSize: 12}}>$150.000 - 1.636 USD</p>
                            <p className='mt-3 mb-0' style={{fontSize: 12}}><img className='me-1' style={{width: 20, borderRadius: "50%"}} src="https://secdevs.com.ar/cbimage.png" alt=""></img> <span style={{marginTop: 'auto', marginBottom: 'auto', height: 20, verticalAlign: 'middle'}}>Gabriel Alvarez @ {date}</span></p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        {comment(true, 0, 'Recibo', '', "hola.pdf", '10/02/2022 10:00')}
                        {comment(false, 0, 'Factura', 'FA-00001-00056789', "hola.pdf", '10/02/2022 10:00')}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        )
    }

    const comment = function(first, id, text, subtitle, fileUri, date){
        let file = false;
        let fileExt = '';

        if(fileUri !== null){
            file = true;
            fileExt = fileUri.split('.')[1];
        }

        return(
            <div className={`file element ${first ? 'mt-0' : 'mt-3'}`}>
                <table>
                    <tr>
                        <td className='w-100'>
                            <p className='m-0'>{text}</p>
                            <p className='m-0' style={{fontSize: 12}}>{subtitle}</p>
                            <p className='mt-3 mb-0' style={{fontSize: 12}}><img className='me-1' style={{width: 20, borderRadius: "50%"}} src="https://secdevs.com.ar/cbimage.png" alt=""></img> <span style={{marginTop: 'auto', marginBottom: 'auto', height: 20, verticalAlign: 'middle'}}>Gabriel Alvarez @ {date}</span></p>
                        </td>
                        <td>
                            {fileExtIcon(fileExt)}
                        </td>
                        <td className='w-100'>
                            <Dropdown>
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                    <span style={{marginRight: 10}}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {file ? <Dropdown.Item href="#/action-1"><FontAwesomeIcon icon={faEye} style={{marginRight: 8}} />Ver</Dropdown.Item> : ''}
                                    {file ? <Dropdown.Item href="#/action-1"><FontAwesomeIcon icon={faDownload} style={{marginRight: 9, marginLeft: 1}} />Descargar</Dropdown.Item> : ''}
                                    <Dropdown.Item href="#/action-3"><FontAwesomeIcon icon={faTrash} style={{marginRight: 10, marginLeft: 2}} />Eliminar</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                    </tr>
                </table>
            </div>
        )
    }

    const vesselFinder = function(first){

        return(
            <div className={`map element mt-0}`}>
                <iframe name="marinetraffic" id="marinetraffic" height="500" width="100%" scrolling="no" frameborder="0" src="https://www.marinetraffic.com/en/ais/embed/zoom:7/maptype:4/shownames:false/mmsi:0/shipid:6883302/vtypes:/showmenu:true/remember:false"></iframe>
            </div>
        )
    }

    const fileExtIcon = function(ext){
        if(ext === 'pdf'){
            return (<FontAwesomeIcon icon={faFilePdf} style={{marginRight: 20, color: '#DC3545', fontSize: 30}} />)
        }
        if(ext === 'xlsx'){
            return (<FontAwesomeIcon icon={faFileExcel} style={{marginRight: 20, color: '#28A745', fontSize: 30}} />)
        }
    }
    
    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontWeight: 300}}>
                                    <p style={{fontSize: 20}} className='text-muted mb-0'>Operación</p>
                                    <p style={{color: '#A0A0A0', fontSize: 45}}>GYV123</p>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={faShip} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-4'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left50'>
                                    <Link to={'/operaciones'} className='linkBlue'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: 8}} />Volver a Operaciones
                                    </Link>
                                    <span className='ms-3' style={{color: '#A0A0A0'}}>|</span><span className='ms-3'><Badge bg="warning" className='opTable status'>Abierta</Badge></span>
                                </td>
                                <td className='header rows right'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                            <span style={{marginRight: 10}}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1"><FontAwesomeIcon icon={faPen} style={{marginRight: 8}} />Modificar estado</Dropdown.Item>
                                            <Dropdown.Item href="#/action-1"><FontAwesomeIcon icon={faPlusCircle} style={{marginRight: 8}} />Añadir elemento</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2"><FontAwesomeIcon icon={faFileExcel} style={{marginRight: 10, marginLeft: 2}} />Exportar historial a Excel</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2"><FontAwesomeIcon icon={faFileExcel} style={{marginRight: 10, marginLeft: 2}} />Exportar carátula a Excel</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        </table>
                    

                        <Container>
                            <Row className='mt-4 mb-4 ps-3 pe-3'>
                                {cover("05/01/2022 10:00")}

                            
                                {comment(false, 0, 'Factura proforma', '', "hola.pdf", '10/02/2022 10:00')}
                                {comment(false, 0, 'Cotización', '', "hola.xlsx", '10/02/2022 10:00')}
                                
                            </Row>
                        </Container>

                        
                    </Card>
                </Row>
            </Container>



            <Container>
                <Row>
                    <Card className='cards content mb-4'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left50'>
                                    <span>Pre embarque</span>
                                </td>
                                <td className='header rows right'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                            <span style={{marginRight: 10}}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1"><FontAwesomeIcon icon={faEye} style={{marginRight: 8}} />Ver</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2"><FontAwesomeIcon icon={faDownload} style={{marginRight: 9, marginLeft: 1}} />Descargar</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3"><FontAwesomeIcon icon={faTrash} style={{marginRight: 10, marginLeft: 2}} />Eliminar</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        </table>
                    

                        <Container>
                            <Row className='mt-4 mb-4 ps-3 pe-3'>
                                {payment('10/02/2022 10:00')}
                                {comment(false, 0, 'El proveedor avisó que no tiene stock de la materia prima, por lo que el ETD se va a atrasar', '', null, '10/02/2022 10:00')}
                            </Row>
                        </Container>

                        
                    </Card>
                </Row>
            </Container>

            <Container>
                <Row>
                    <Card className='cards content mb-4'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left50'>
                                    <span>Tránsito</span>
                                </td>
                                <td className='header rows right'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                            <span style={{marginRight: 10}}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1"><FontAwesomeIcon icon={faEye} style={{marginRight: 8}} />Ver</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2"><FontAwesomeIcon icon={faDownload} style={{marginRight: 9, marginLeft: 1}} />Descargar</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3"><FontAwesomeIcon icon={faTrash} style={{marginRight: 10, marginLeft: 2}} />Eliminar</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        </table>
                    

                        <Container>
                            <Row className='mt-4 mb-4 ps-3 pe-3'>
                                {vesselFinder(false)}
                                {comment(false, 0, 'El buque va a hacer escala en San Pablo, se va a demorar el ETA 1 mes', '', "hola.pdf", '10/02/2022 10:00')}
                            </Row>
                        </Container>

                        
                    </Card>
                </Row>
            </Container>

            <Container>
                <Row>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left50'>
                                    <span>Arribo</span>
                                </td>
                                <td className='header rows right'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                            <span style={{marginRight: 10}}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1"><FontAwesomeIcon icon={faEye} style={{marginRight: 8}} />Ver</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2"><FontAwesomeIcon icon={faDownload} style={{marginRight: 9, marginLeft: 1}} />Descargar</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3"><FontAwesomeIcon icon={faTrash} style={{marginRight: 10, marginLeft: 2}} />Eliminar</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        </table>
                    

                        <Container>
                            <Row className='mt-4 mb-4 ps-3 pe-3'>
                                {comment(true, 0, 'El buque llegó a destino', null, '10/02/2022 10:00')}
                            </Row>
                        </Container>

                        
                    </Card>
                </Row>
            </Container>
        </div>
    )
}